
import { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './App.scss';
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card'
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import Carousel from 'react-bootstrap/Carousel';
//import TagManager from 'react-gtm-module';
//var request = require('request');
function App() {
	const [fname, setFname] = useState("");	
	const [lname, setLname] = useState("");
	const [mobileNo, setMobileNo] = useState("");
	const [dateTime, setDateTime] = useState("");
	const [pickup, setPickup] = useState("");
	const [drop, setDrop] = useState("");
	const [error, setError] = useState("");
	const [callBackNumber, setCallBackNumber] = useState("");
	const [show, setShow] = useState(true);
	const [handleClose, setHandleClose] = useState(false);
	//setShow(true)
	async function sendSMS () {
		console.log("Function called");
		//console.log("process.env.REACT_APP_APIURL"+process.env.REACT_APP_APIURL)
		//let urlData = "&mobileNo=" + this.state.mobileNo + "&userPassword=" + this.state.userPassword;
        const headers = { 'Content-Type': 'application/json' }
        //console.log("urlData=="+urlData);
		if (fname =='') {
            setError("Please enter first name" )
            return false;
        }
		if (lname =='') {
            setError("Please enter last name" )
            return false;
        }
		if (pickup == "" || pickup == null) {
            setError("Please enter pickup location" )
            return false;
        }
		if (drop == "" || drop == null) {
            setError("Please enter drop location" )
            return false;
        }if (mobileNo=='') {
            setError("Please enter mobile number" )
            return false;
        }
        if (mobileNo.length !=10) {
            setError("Please enter valid mobile number" )
            return false;
        }
		setError("" )
		let pickupCityName=pickup;
		let dropCityName=drop;
		let journeyDate=dateTime
		let customermobile=fname +' '+lname+" ("+ mobileNo +")";
		let templateId="1407169457835885962";
		try {
		let msg="Dear Admin, Customer "+mobileNo+" Searched for Trip From "+pickupCityName+" To "+dropCityName+" On Date "+journeyDate+" Vishwajeet Tours and Travels";
		
		let urlData={
            mobileNo:customermobile,
            pickupCityName:pickupCityName,
            destinationCity:dropCityName,
            pickdateTime:journeyDate

        };
        let bookingData = await axios.post('https://api.vishwacarrental.com/user/send_sms' , urlData, { headers });
		console.log("bookingData:"+bookingData);
		setFname('');
		setLname('');
		setMobileNo('');
		setDateTime('');
		setPickup('');
		setDrop('');
		setError("Thanks you for your Interest, We will call you back soon" );
		
		}catch (e) {
			console.log("In catch send sms");
		}
        //return true;
       	// const response = await axios.get(url, { headers });
        //let result = await axios.get(url);
        // console.log("+++response=="+JSON.stringify(response));
        //const result = await response.json();
	}
	async function handleCloseCallBack(){
        setShow(false)
    }
    async function sendCallBack (){        
        
        if (callBackNumber == "" || callBackNumber == null) {
            setError("Please enter mobile number" )
            return false;
        }
        if (callBackNumber.length !=10) {
            setError("Please enter valid mobile number" )
            return false;
        }
        
        let urlData={
            callBackNumber:callBackNumber
        }
        try {
            
            const headers = {'Content-Type':'application/json' };
            
            let cabData = await axios.post('https://api.vishwacarrental.com/user/request_callback' , urlData, { headers });
            
            if (cabData.data.code == 200) {
                //this.setState({ item: cabData.data.data });
                setError("Thank you for your interest, we will call you back shortly");
                setCallBackNumber('');
            } else {
                
            }
        } catch (err) {
            
        }       
    }
	//sendSMS();

  return (
    <div className="App">
		
		<div className='row top-header' style={{ backgroundColor: '#0e0d0d' }}>
            <div className="header-detail row" style={{ width: '100%', marginLeft: 30 }}>
                <div className='row' style={{ width: '100%',paddingTop:10,paddingBottom:10}} >
                    <div className='col-md-4 col-sm-12'>
                        <a href="https://api.whatsapp.com/send?phone=7688811106" ><i className="fa-brands fa-whatsapp top-icon" aria-hidden="true"> </i> What's App</a>
                    </div>
                    <div className='col-md-4 col-sm-12'> 
                        <a href="tel:+91 7688811106" className='blinkTop'><i className="fa-solid fa-phone-volume top-icon" aria-hidden="true"></i> +91 7688811106</a>
                    </div>
                    <div className='col-md-4 col-sm-12'>
                        <a href="mailto:viswajeetcabservices@gmail.com" title="Email Us"> <i className="fa fa-envelope top-icon" aria-hidden="true"></i> </a>
                        <a href="#" title="Facebook"> <i className="fa-brands fa-facebook top-icon" aria-hidden="true"></i> </a>
                        <a href="#" title="YouTube"> <i className="fa-brands fa-youtube top-icon" aria-hidden="true"></i> </a>
                        <a href="#" title="Instagram"> <i className="fa-brands fa-instagram top-icon" aria-hidden="true"></i> </a>
                        <a href="#" title="LinkedIn"> <i className="fa-brands fa-linkedin top-icon" aria-hidden="true"></i> </a>
                    </div>
                </div>
            </div>
        </div>
      <header className="App-header">
		<Modal
			show={show}
			onHide={handleCloseCallBack}
			backdrop="static"
			keyboard={false}
			className="callBackDailog"
		>
			<Modal.Header closeButton>
			<Modal.Title>Quick Callback Request</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<span style={{ color: 'red' }}>{error}</span><br/>
			I would like to get callback on my number
				<Form.Group controlId="formBasicEmail" >
					<Form.Label>Mobile No. <p style={{ color: 'red' }}>*</p></Form.Label>
					<Form.Control name="callbackNumber" type="text" placeholder="Call Back Number" value={callBackNumber} onChange={(e) => setCallBackNumber(e.target.value)} />
				</Form.Group>
			</Modal.Body>
			<Modal.Footer>          
			<Button variant="primary" onClick={sendCallBack}>Call Me Back</Button>
			</Modal.Footer>
        </Modal>
		<div style={{position:'fixed',bottom:'100px',right:'20px'}}>
			<div className='col-md-4 col-sm-12 call-btn-rounded ' style={{position:'absolute',bottom:'70px'}}>
				<span className='blinkbtn'>
					<a href="https://api.whatsapp.com/send?phone=7688811106" ><i className="fa-brands fa-whatsapp top-icon" style={{fontSize:'20px'}} aria-hidden="true"> </i></a>
				</span>
            </div>
            <div className='col-md-4 col-sm-12 call-btn-rounded blinkbtn' style={{position:'absolute',bottom:'20px'}}> 
				<span className='blinkbtn'>
					<a href="tel:+91 7688811106">					
						<i className="fas fa-phone-alt top-icon" style={{fontSize:'20px'}} aria-hidden="true"></i>
					</a>
				</span>
            </div>
		</div>
		<nav className="navbar navbar-expand-lg navbar-dark menu shadow fixed-top">
			<div className="container">
			  <a className="navbar-brand" href="">
				<h3>Vishwajeet Cab Services</h3>
			  </a>
			  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
				<span className="navbar-toggler-icon"></span>
			  </button>
			  <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
				<ul className="navbar-nav">
				  <li className="nav-item"><a className="nav-link active" aria-current="page" href="#Home">Home</a></li>
				  <li className="nav-item"><a className="nav-link" href="#services">Services</a></li>
				  <li className="nav-item"><a className="nav-link" href="#testimonials">FeedBack</a></li>
				  <li className="nav-item"><a className="nav-link" href="#faq">Faq</a></li>
				  <li className="nav-item"><a className="nav-link" href="#contact">Contact</a>
				  </li>
				</ul>
				<button type="button" className="rounded-pill btn-rounded ">
				  <a href="tel:+91 7688811106" className='blink'>+91 7688811106</a>
				  <span>
					<i className="fas fa-phone-alt"></i>
				  </span>
				</button>
			  </div>
			</div>
		</nav>
		
		
		  <section id="contact" className="get-started">
			<div className="container">
				<div className="row text-center">
				<h1 className="display-3 fw-bold text-capitalize">Book Now</h1>
				<div className="heading-line"></div>
				
				</div>				
				<div className="row text-white">
					
				<div className="col-12 col-lg-6 bg-white shadow p-3">
					<div className="form w-100 pb-2">
					<h4 className="display-3--title mb-5">Book Now</h4>
					<form action="#" className="row">
						<div style={{color:'red',fontWeight:'bold'}}>{error}</div>
						<div className="col-lg-6 col-md mb-3">
						<input type="text" placeholder="First Name" value={fname} onChange={(e) => setFname(e.target.value)} id="inputFirstName" className="shadow form-control form-control-lg"/>
						</div>
						<div className="col-lg-6 col-md mb-3">
						<input type="text" placeholder="Last Name" value={lname} onChange={(e) => setLname(e.target.value)} id="inputLastName" className="shadow form-control form-control-lg"/>
						</div>
						<div className="col-lg-6 col-md mb-3">
						<input type="text" placeholder="PickUp" value={pickup} onChange={(e) => setPickup(e.target.value)} id="inputPickUp" className="shadow form-control form-control-lg"/>
						</div>
						<div className="col-lg-6 col-md mb-3">
						<input type="text" placeholder="Drop" value={drop} onChange={(e) => setDrop(e.target.value)} id="inputDrop" className="shadow form-control form-control-lg"/>
						</div>
						<div className="col-lg-12 mb-3">
						<input type="text" placeholder="Date Time" value={dateTime} onChange={(e) => setDateTime(e.target.value)} id="inputDateTime" className="shadow form-control form-control-lg"/>
						</div>
						<div className="col-lg-12 mb-3">
						<input type="text" placeholder="mobile No." value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} id="inputMobile" className="shadow form-control form-control-lg"/>
						</div>
						
						<div className="text-center d-grid mt-1">
						<button type="button" onClick={sendSMS} className="btn btn-primary rounded-pill pt-3 pb-3">
							submit
							<i className="fas fa-paper-plane"></i>
						</button>
						</div>
					</form>
					</div>
				</div>
				<div className="col-12 col-lg-6 gradient shadow p-3">
					<div className="cta-info w-100">
					<h4 className="display-4 fw-bold">100% Satisfaction Guaranteed</h4>
					<p className="lh-lg">
						We at Vishwajeet Cab Services having 10+ Years of experience in this business and we run 5Lakh+ Km and more then 1000+ Happy Customers
					</p>
					<h3 className="display-3--brief">What will be the next step?</h3>
					<ul className="cta-info__list" style={{textAlign:'left'}}>
						<li>Best Price Guaranteed</li>
						<li>24/7 Customer Care</li>
						<li>Home Pickup and Drop</li>
						<li>Trained and professional drivers</li>
						<li>Well Maintained Cars</li>
						<li>Easy Booking</li>
					</ul>
					</div>
				</div>
				</div>
			</div>
		</section>
		<section id="home" className="intro-section">
		  <div className="container">
			<div className="row align-items-center text-white">
			  
			  <div className="col-md-6 intros text-start">
				<h1 className="display-2">
				  <span className="display-2--intro">Vishwajeet Cab Services</span>
				  <span className="display-2--description lh-base">
					  Miles of Smiles, One Rental Away
				  </span>
				</h1>
				
			  </div>
			  
			  <div className="col-md-6 intros text-end">
				<div className="video-box">
				  <img src="images/siyaramcabs.png" alt="video illutration" className="img-fluid"/>				  
				</div>
			  </div>
			</div>
		  </div>
		  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#ffffff" fillOpacity="1" d="M0,160L48,176C96,192,192,224,288,208C384,192,480,128,576,133.3C672,139,768,213,864,202.7C960,192,1056,96,1152,74.7C1248,53,1344,107,1392,133.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
		</section>
		<section id="pricing" className="pricing">

			<div className="container" data-aos="fade-up">
				<div className="section-title">
					<h2>Regular Trips
						
					</h2>
				</div>
				<div className="row HomeCards">
					
					<div className="col-lg-4 col-md-4" style={{ marginTop: 20, paddingRight: 5, paddingLeft: 5 }}>

						<Card>
							<Card.Img variant="top" src="https://www.vishwajeetcabservice.com/images/sedan.png" alt="Car Image" style={{ height: 200 }} />
							<Card.Body>
								<div className="row" style={{padding: "4px"}}>
									<div className="col-5" title="Capacity" style={{ padding: "0px",  fontSize: 12, textAlign: "left", fontWeight: 700 }}>
										<i style={{ color: '#60e390' }} className="fa fa-users" aria-hidden="true"></i>  4
									</div>
									<div className="col-3" style={{ padding: "0px", fontSize: 12, textAlign: "center" }}>
										<i style={{ color: '#60e390' }} className="fa fa-suitcase" aria-hidden="true"></i>  3
									</div>
									<div className="col-4" style={{ textAlign: "end" }}>
										<i style={{ color: '#60e390' }} className="fa fa" aria-hidden="true">AC</i> 
									</div>
								</div>
								<Card.Title><h5>Sedan </h5></Card.Title>
								<div style={{ color: '#60e390' }}>
									<i className="fa fa-star" aria-hidden="true"></i>
									<i className="fa fa-star" aria-hidden="true"></i>
									<i className="fa fa-star" aria-hidden="true"></i>
									<i className="fa fa-star" aria-hidden="true"></i>
									<i className="fa fa-star-half-o" aria-hidden="true"></i>
								</div>

								<div className="row">
									<div className="col-3" style={{ padding: "4px", fontSize: 12, textAlign: "center", fontWeight:'bold' }}><i className="fa fa-inr" aria-hidden="true"></i>12/Km</div>
									<div className="col-3" style={{ padding: "4px", fontSize: 12, textAlign: "center" }}>Sedan</div>
									<div className="col-6" style={{ textAlign: "end" }}>
										<Button variant="primary" style={{ padding: "4px", fontSize: 12, textAlign: "center" }}><a href="#contact" style={{color:'white',textDecoration:'none', fontWeight:'bold'}}>Book Now</a></Button>
									</div>
								</div>
							</Card.Body>
						</Card>
					</div>
					<div className="col-lg-4 col-md-4" style={{ marginTop: 20, paddingRight: 5, paddingLeft: 5 }}>
						<Card>
							<Card.Img variant="top" src="https://www.vishwajeetcabservice.com/images/hachback.jpg" alt="Car Image" style={{ height: 200 }} />
							<Card.Body>
								<div className="row" style={{padding: "4px"}}>
									<div className="col-5" title="Capacity" style={{ padding: "0px",  fontSize: 12, textAlign: "left", fontWeight: 700 }}>
										<i style={{ color: '#60e390' }} className="fa fa-users" aria-hidden="true"></i>  4
									</div>
									<div className="col-3" style={{ padding: "0px", fontSize: 12, textAlign: "center" }}>
										<i style={{ color: '#60e390' }} className="fa fa-suitcase" aria-hidden="true"></i>  3
									</div>
									<div className="col-4" style={{ textAlign: "end" }}>
										<i style={{ color: '#60e390' }} className="fa fa" aria-hidden="true">AC</i> 
									</div>
								</div>
								<Card.Title>HatchBack</Card.Title>
								<div style={{ color: '#60e390' }}>
									<i className="fa fa-star" aria-hidden="true"></i>
									<i className="fa fa-star" aria-hidden="true"></i>
									<i className="fa fa-star" aria-hidden="true"></i>
									<i className="fa fa-star" aria-hidden="true"></i>
									<i className="fa fa-star-half-o" aria-hidden="true"></i>
								</div>
								<div className="row">
									<div className="col-3" style={{ padding: "4px", fontSize: 12, textAlign: "center" }}><i className="fa fa-inr" aria-hidden="true"></i>11/Km</div>
									<div className="col-3" style={{ padding: "4px", fontSize: 12, textAlign: "center" }}>HatchBack</div>
									<div className="col-6" style={{ textAlign: "end" }}>
										<Button variant="primary" style={{ padding: "4px", fontSize: 12, textAlign: "center" }}><a href="#contact" style={{color:'white',textDecoration:'none', fontWeight:'bold'}}>Book Now</a></Button>
									</div>
								</div>
							</Card.Body>
						</Card>
					</div>
					<div className="col-lg-4 col-md-4" style={{ marginTop: 20, paddingRight: 5, paddingLeft: 5 }}>
						<Card>
							<Card.Img variant="top" src="https://www.vishwajeetcabservice.com/images/ertiga.png" alt="Car Image" style={{ height: 200 }} />
							<Card.Body>
								<div className="row" style={{padding: "4px"}}>
									<div className="col-5" title="Capacity" style={{ padding: "0px",  fontSize: 12, textAlign: "left", fontWeight: 700 }}>
										<i style={{ color: '#60e390' }} className="fa fa-users" aria-hidden="true"></i>  4
									</div>
									<div className="col-3" style={{ padding: "0px", fontSize: 12, textAlign: "center" }}>
										<i style={{ color: '#60e390' }} className="fa fa-suitcase" aria-hidden="true"></i>  3
									</div>
									<div className="col-4" style={{ textAlign: "end" }}>
										<i style={{ color: '#60e390' }} className="fa fa" aria-hidden="true">AC</i> 
									</div>
								</div>
								<Card.Title>Ertiga</Card.Title>
								<div style={{ color: '#60e390' }}>
									<i className="fa fa-star" aria-hidden="true"></i>
									<i className="fa fa-star" aria-hidden="true"></i>
									<i className="fa fa-star" aria-hidden="true"></i>
									<i className="fa fa-star" aria-hidden="true"></i>
									<i className="fa fa-star-half-o" aria-hidden="true"></i>
								</div>
								<div className="row">
									<div className="col-3" style={{ padding: "4px", fontSize: 12, textAlign: "center" }}><i className="fa fa-inr" aria-hidden="true"></i>14/Km</div>
									<div className="col-3" style={{ padding: "4px", fontSize: 12, textAlign: "center" }}>Ertiga</div>
									<div className="col-6" style={{ textAlign: "end" }}>
										<Button variant="primary" style={{ padding: "4px", fontSize: 12, textAlign: "center" }}><a href="#contact" style={{color:'white',textDecoration:'none', fontWeight:'bold'}}>Book Now</a></Button>
									</div>
								</div>
							</Card.Body>
						</Card>
					</div>
					<div className="col-lg-4 col-md-4" style={{ marginTop: 20, paddingRight: 5, paddingLeft: 5 }}>
						<Card >
							<Card.Img variant="top" src="https://www.vishwajeetcabservice.com/images/innova.png" alt="Car Image" style={{ height: 200 }} />
							<Card.Body>
								<div className="row" style={{padding: "4px"}}>
									<div className="col-5" title="Capacity" style={{ padding: "0px",  fontSize: 12, textAlign: "left", fontWeight: 700 }}>
										<i style={{ color: '#60e390' }} className="fa fa-users" aria-hidden="true"></i>  4
									</div>
									<div className="col-3" style={{ padding: "0px", fontSize: 12, textAlign: "center" }}>
										<i style={{ color: '#60e390' }} className="fa fa-suitcase" aria-hidden="true"></i>  3
									</div>
									<div className="col-4" style={{ textAlign: "end" }}>
										<i style={{ color: '#60e390' }} className="fa fa" aria-hidden="true">AC</i> 
									</div>
								</div>
								<Card.Title>Innova</Card.Title>
								<div style={{ color: '#60e390' }}>
									<i className="fa fa-star" aria-hidden="true"></i>
									<i className="fa fa-star" aria-hidden="true"></i>
									<i className="fa fa-star" aria-hidden="true"></i>
									<i className="fa fa-star" aria-hidden="true"></i>
									<i className="fa fa-star-half-o" aria-hidden="true"></i>
								</div>
								<div className="row">
									<div className="col-3" style={{ padding: "4px", fontSize: 12, textAlign: "center" }}><i className="fa fa-inr" aria-hidden="true"></i>17/Km</div>
									<div className="col-3" style={{ padding: "4px", fontSize: 12, textAlign: "center" }}>Innova</div>
									<div className="col-6" style={{ textAlign: "end" }}>
										<Button variant="primary" style={{ padding: "4px", fontSize: 12, textAlign: "center" }}><a href="#contact" style={{color:'white',textDecoration:'none', fontWeight:'bold'}}>Book Now</a></Button>
									</div>
								</div>
							</Card.Body>
						</Card>
					</div>
					<div className="col-lg-4 col-md-4" style={{ marginTop: 20, paddingRight: 5, paddingLeft: 5 }}>
						<Card >
							<Card.Img variant="top" src="https://www.vishwajeetcabservice.com/images/innova-crysta.webp" alt="Car Image" style={{ height: 200 }} />
							<Card.Body>
								<div className="row" style={{padding: "4px"}}>
									<div className="col-5" title="Capacity" style={{ padding: "0px",  fontSize: 12, textAlign: "left", fontWeight: 700 }}>
										<i style={{ color: '#60e390' }} className="fa fa-users" aria-hidden="true"></i>  4
									</div>
									<div className="col-3" style={{ padding: "0px", fontSize: 12, textAlign: "center" }}>
										<i style={{ color: '#60e390' }} className="fa fa-suitcase" aria-hidden="true"></i>  3
									</div>
									<div className="col-4" style={{ textAlign: "end" }}>
										<i style={{ color: '#60e390' }} className="fa fa" aria-hidden="true">AC</i> 
									</div>
								</div>
								<Card.Title>Innova Crysta</Card.Title>
								<div style={{ color: '#60e390' }}>
									<i className="fa fa-star" aria-hidden="true"></i>
									<i className="fa fa-star" aria-hidden="true"></i>
									<i className="fa fa-star" aria-hidden="true"></i>
									<i className="fa fa-star" aria-hidden="true"></i>
									<i className="fa fa-star-half-o" aria-hidden="true"></i>
								</div>
								<div className="row">
									<div className="col-3" style={{ padding: "4px", fontSize: 12, textAlign: "center" }}><i className="fa fa-inr" aria-hidden="true"></i>
										19/Km</div>
									<div className="col-3" style={{ padding: "4px", fontSize: 12, textAlign: "center" }}>Innova Crysta</div>
									<div className="col-6" style={{ textAlign: "end" }}>
										<Button variant="primary" style={{ padding: "4px", fontSize: 12, textAlign: "center" }}><a href="#contact" style={{color:'white',textDecoration:'none', fontWeight:'bold'}}>Book Now</a></Button>
									</div>
								</div>
							</Card.Body>
						</Card>
					</div>
				</div>
			</div>

		</section>

		<section id="services" className="services">
		  <div className="container">
			<div className="row text-center">
			  <h1 className="display-3 fw-bold">Our Services</h1>
			  <div className="heading-line mb-1"></div>
			</div>
		  
			
		  </div>

		  
		  <div className="container">
			
			<div className="row">
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4">
				<div className="services__content">
				  <div className="icon d-block fa fa-car"></div>
				  <h3 className="display-3--title mt-1">Local Car Rentals</h3>
				  <p className="lh-lg">
					We provide service for all major cities in india. you can book from anywhere and go to any where within city in india.
				  </p>
				  
				</div>
			  </div>
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4 text-end">
				<div className="services__pic">
				  <img src="images/services/localCars.jpg" alt="marketing illustration" className="img-fluid"/>
				</div>
			  </div>
			</div>
			
			<div className="row">
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4 text-start">
				<div className="services__pic">
				  <img src="images/services/service-2.jpg" alt="web development illustration" className="img-fluid"/>
				</div>
			  </div>
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4">
				<div className="services__content">
				  <div className="icon d-block fa fa-car"></div>
				  <h3 className="display-3--title mt-1">Outstation Taxi</h3>
				  <p className="lh-lg">
					We also provides taxi/cabs for go in any outstaion within india. you can book our car from any where and go to any where in india.

		We have collection of multiple cars you can book any one as you want

		Sedan: Book Sedan car with driver and you can travel up to 4 people and 2 luguege bags
		Innova/SUV/: Book innova/SUV car with driver and you can travel up to 6 people and 2 luguege bags
		Crysta: Book Crysta car with driver and you can travel up to 6 people and 2 luguege bags
				  </p>
				  
				</div>
			  </div>
			</div>
			
			<div className="row">
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4">
				<div className="services__content">
				  <div className="icon d-block fa fa-car"></div>
				  <h3 className="display-3--title mt-1">One way cabs</h3>
				  <p className="lh-lg">
					We proivde one way pickup and drop service from any location in india.
				  </p>
				  
				</div>
			  </div>
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4 text-end">
				<div className="services__pic">
				  <img src="images/services/oneway.png" alt="cloud hosting illustration" className="img-fluid"/>
				</div>
			  </div>
			</div>
			
			<div className="row">
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4 text-start">
				<div className="services__pic">
				  <img src="images/services/airport-taxi.png" alt="web development illustration" className="img-fluid"/>
				</div>
			  </div>
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4">
				<div className="services__content">
				  <div className="icon d-block fa fa-plane"></div>
				  <h3 className="display-3--title mt-1">Airport Taxi</h3>
				  <p className="lh-lg">
					We also provide booking for airport in india
				  </p>
				  
				</div>
			  </div>
			</div>
		  </div>
		</section>
		<section>
			<div className="container-xxl py-5">
				<div className="container py-5 px-lg-5">
										
					<div className="row g-4 portfolio-container">
						<div className="col-lg-4 col-md-6 portfolio-item box-shadow first wow fadeInUp" data-wow-delay="0.1s">
							<div className="rounded overflow-hidden">
								<div className="position-relative overflow-hidden card-image">
									<img className="img-fluid w-100 card-img" src="images/pune.jpg" alt=""/>
									<div className="portfolio-overlay">
										<a className="btn btn-square btn-outline-light mx-1" href="images/pune.jpg" data-lightbox="portfolio"><i className="fa fa-eye"></i></a>
									</div>
								</div>                            
								<div className="bg-light p-4">
									<p className="text-primary fw-medium mb-2">Mumbai To Pune</p>
									<p className="lh-base mb-0 text-data">
									Pune, a city in the Indian state of Maharashtra, is known for its rich history, vibrant culture, and a mix of modern and traditional attractions. Here are some popular places to visit in Pune:

										<p><b>Shaniwar Wada:</b> This historic fortification and palace is an iconic landmark in Pune, known for its intricate architecture and historical significance.</p>

										<p><b>Dagdusheth Halwai Ganpati Temple:</b> This famous Ganesh temple is a center of devotion and a popular tourist destination.</p>
										<p><b>Sinhagad Fort:</b> Located on a hill, this fort offers stunning views of the surrounding countryside. It's a popular spot for trekking and picnics.</p>
										<p><b>Saras Baug:</b> A well-maintained garden with a Ganesh temple and a beautiful lake.
										Darshan Museum: This museum provides insights into the life and teachings of Sadhu Vaswani, a spiritual leader.</p>
									</p>
								</div>
								
							</div>
						</div>
						<div className="col-lg-4 col-md-6 portfolio-item box-shadow second wow fadeInUp" data-wow-delay="0.3s">
							<div className="rounded overflow-hidden">
								<div className="position-relative overflow-hidden card-image">
									<img className="img-fluid w-100 card-img" src="images/kolhapur.png" alt=""/>
									<div className="portfolio-overlay">
										<a className="btn btn-square btn-outline-light mx-1" href="images/kolhapur.png" data-lightbox="portfolio"><i className="fa fa-eye"></i></a>
									</div>
								</div>
								
								<div className="bg-light p-4">
									<p className="text-primary fw-medium mb-2">Mumbai To Kolhapur</p>
									<p className="lh-base mb-0 text-data">
									Kolhapur is a city located in the southwestern part of the Indian state of Maharashtra. It is the district headquarters of the Kolhapur district and is known for its historical, cultural, and economic significance. Here are some key aspects of Kolhapur:
										<p><b>Mahalaxmi Temple:</b> The Mahalaxmi Temple is one of the most famous religious sites in Kolhapur. It is dedicated to the goddess Mahalaxmi and is known for its architectural beauty and spiritual significance.</p>

										<p><b>Shri Chhatrapati Shahu Museum:</b> This historical palace, built in the 19th century, serves as a museum showcasing artifacts and memorabilia from the Chhatrapati rulers of Kolhapur. The palace architecture and the surrounding gardens are also worth exploring.</p>
										<p><b>Rankala Lake:</b> Rankala Lake is a picturesque and popular spot in Kolhapur. It's an ideal place for leisurely walks, boating, and enjoying the scenic beauty. The lake has a serene ambiance and is particularly lovely during sunset.</p>
										<p><b>Panhala Fort:</b> Situated about 20 kilometers from Kolhapur, Panhala Fort is one of the largest and most important forts in Maharashtra. It offers a glimpse into the historical significance of the region and provides panoramic views of the landscape.</p>
									</p>
								</div>
								
							</div>
						</div>
						<div className="col-lg-4 col-md-6 portfolio-item box-shadow first wow fadeInUp" data-wow-delay="0.5s">
							<div className="rounded overflow-hidden">
								<div className="position-relative overflow-hidden card-image">
									<img className="img-fluid w-100 card-img" src="images/shirdi.jpg" alt=""/>
									<div className="portfolio-overlay">
										<a className="btn btn-square btn-outline-light mx-1" href="images/shirdi.jpg" data-lightbox="portfolio"><i className="fa fa-eye"></i></a>
									</div>
								</div>
								
								<div className="bg-light p-4">
									<p className="text-primary fw-medium mb-2">Mumbai To Shirdi</p>
									<p className="lh-base mb-0 text-data">
									Shirdi is a small town located in the Ahmednagar district of the Indian state of Maharashtra. It is famous for being the home of the revered Indian spiritual leader, Sai Baba, who lived there for much of his life. Sai Baba is a prominent figure in Indian spirituality and is venerated by people of various religious backgrounds.
										<p><b>Sai Baba Temple:</b> This is the main temple in Shirdi, dedicated to Sai Baba. It is one of the most visited and revered places in the town.
										</p>
										<p><b>Chavadi:</b> Chavadi is a building where Sai Baba used to spend alternate nights. It has become a place of religious importance.</p>
										<p><b>Samadhi Mandir:</b> This is the place where the mortal remains of Sai Baba are interred. Devotees come here to pay their respects.</p>
										
									</p>
								</div>                            
							</div>
						</div>
						<div className="col-lg-4 col-md-6 portfolio-item box-shadow second wow fadeInUp" data-wow-delay="0.1s">
							<div className="rounded overflow-hidden">
								<div className="position-relative overflow-hidden card-image">
									<img className="img-fluid w-100 card-img" src="images/bhimashankar.jpg" alt=""/>
									<div className="portfolio-overlay">
										<a className="btn btn-square btn-outline-light mx-1" href="images/bhimashankar.jpg" data-lightbox="portfolio"><i className="fa fa-eye"></i></a>
									</div>
								</div>
								
								<div className="bg-light p-4">
									<p className="text-primary fw-medium mb-2">Mumbai To Bhimashankar</p>
									<p className="lh-base mb-0 text-data">The Bhimashankar Temple is one of the 12 Jyotirlinga temples in India and is dedicated to Lord Shiva. It is located in the Sahyadri range of the Western Ghats, within the Bhimashankar Wildlife Sanctuary in the Pune district of Maharashtra. The temple is renowned for its religious significance, architectural beauty, and its picturesque natural surroundings.</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 portfolio-item box-shadow first wow fadeInUp" data-wow-delay="0.3s">
							<div className="rounded overflow-hidden">
								<div className="position-relative overflow-hidden card-image">
									<img className="img-fluid w-100 card-img" src="images/Mahabaleshwar.jpg" alt=""/>
									<div className="portfolio-overlay">
										<a className="btn btn-square btn-outline-light mx-1" href="images/Mahabaleshwar.jpg" data-lightbox="portfolio"><i className="fa fa-eye"></i></a>
										
									</div>
								</div>                            
								<div className="bg-light p-4">
									<p className="text-primary fw-medium mb-2">Mumbai To Mahableshwar</p>
									<p className="lh-base mb-0 text-data">
										Mahabaleshwar is a popular hill station and tourist destination located in the Indian state of Maharashtra. It is situated in the Western Ghats range, at an elevation of about 1,372 meters (4,501 feet) above sea level. Mahabaleshwar is known for its pleasant climate, lush greenery, and breathtaking views
										
										<p><b>Mapro Garden:</b> Mahabaleshwar is famous for strawberries, and the Mapro Garden is a popular attraction where you can learn about strawberry cultivation and taste a variety of strawberry-based products.</p>
										<p><b>Pratapgarh Fort:</b> This historic fort is located near Mahabaleshwar and is associated with the Maratha ruler, Chhatrapati Shivaji. The fort provides a glimpse into the region's historical significance.</p>
									</p>
								</div>                           
							</div>
						</div>
						<div className="col-lg-4 col-md-6 portfolio-item box-shadow first wow fadeInUp" data-wow-delay="0.3s">
							<div className="rounded overflow-hidden">
								<div className="position-relative overflow-hidden card-image">
									<img className="img-fluid w-100 card-img" src="images/Ashtavinayak.jpg" alt=""/>
									<div className="portfolio-overlay">
										<a className="btn btn-square btn-outline-light mx-1" href="images/Ashtavinayak.jpg" data-lightbox="portfolio"><i className="fa fa-eye"></i></a>
										
									</div>
								</div>                            
								<div className="bg-light p-4">
									<p className="text-primary fw-medium mb-2">Mumbai To Ashthvinayak Darshan</p>
									<p className="lh-base mb-0 text-data">
										The Ashtavinayak Darshan is a pilgrimage tour that takes devotees to visit eight ancient temples in the Indian state of Maharashtra, which are dedicated to Lord Ganesha, one of the most widely worshipped deities in Hinduism. These temples are collectively known as the "Ashtavinayak" temples, and the pilgrimage is considered highly sacred by followers of Lord Ganesha.
									</p>
								</div>                           
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section id="testimonials" className="testimonials">
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fff" fillOpacity="1" d="M0,96L48,128C96,160,192,224,288,213.3C384,203,480,117,576,117.3C672,117,768,203,864,202.7C960,203,1056,117,1152,117.3C1248,117,1344,203,1392,245.3L1440,288L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg>
		<div className="container">
			<div className="row text-center text-white">
			<h1 className="display-3 fw-bold">Feedback</h1>
			<hr style={{width: "100", height: "3"}} className="mx-auto"/>
			<p className="lead pt-1">what our customer are saying</p>
			</div>

			
			<div className="row align-items-center">
			<div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
				<div className="carousel-inner">
				
				<div className="carousel-item active">
					
					<div className="testimonials__card">
					<p className="lh-lg">
						<i className="fas fa-quote-left"></i>
						I had a fantastic experience with your car booking. The driver was friendly, and the pick-up and drop-off process was quick and efficient. The car was clean and in great condition. I'll definitely rent from you again.
						<i className="fas fa-quote-right"></i>
						<div className="ratings p-1">
						<i className="fas fa-star"></i>
						<i className="fas fa-star"></i>
						<i className="fas fa-star"></i>
						<i className="fas fa-star"></i>
						<i className="fas fa-star"></i>
						</div>
					</p>
					</div>
					
					<div className="testimonials__picture">
					<img src="images/userIcon.png" alt="client-1 picture" className="rounded-circle img-fluid"/>
					</div>
					
					<div className="testimonials__name">
					<h3>Umesh</h3>
					<p className="fw-light">Happy Customer</p>
					</div>
				</div>     
				
				<div className="carousel-item">
					
					<div className="testimonials__card">
					<p className="lh-lg">
						<i className="fas fa-quote-left"></i>
						The car I rented was spotless and well-maintained. It ran smoothly throughout my trip, and I felt safe and comfortable driving it.
						<i className="fas fa-quote-right"></i>
						<div className="ratings p-1">
						<i className="fas fa-star"></i>
						<i className="fas fa-star"></i>
						<i className="fas fa-star"></i>
						<i className="fas fa-star"></i>
						<i className="fas fa-star"></i>
						</div>
					</p>
					</div>
					
					<div className="testimonials__picture">
					<img src="images/userIcon.png" alt="client-2 picture" className="rounded-circle img-fluid"/>
					</div>
					
					<div className="testimonials__name">
					<h3>Ram</h3>
					<p className="fw-light">Happy Customer</p>
					</div>
				</div>     
				
				<div className="carousel-item">
					
					<div className="testimonials__card">
					<p className="lh-lg">
						<i className="fas fa-quote-left"></i>
						Your rental rates were competitive, and I appreciated the value I received for the price. I didn't feel like I was being overcharged for the quality of service and vehicle I received.
						<i className="fas fa-quote-right"></i>
						<div className="ratings p-1">
						<i className="fas fa-star"></i>
						<i className="fas fa-star"></i>
						<i className="fas fa-star"></i>
						<i className="fas fa-star"></i>
						<i className="fas fa-star"></i>
						</div>
					</p>
					</div>
					
					<div className="testimonials__picture">
					<img src="images/userIcon.png" alt="client-3 picture" className="rounded-circle img-fluid"/>
					</div>
					
					<div className="testimonials__name">
					<h3>Rahul</h3>
					<p className="fw-light">Happy Customer</p>
					</div>
				</div>     
				
				<div className="carousel-item">
					
					<div className="testimonials__card">
					<p className="lh-lg">
						<i className="fas fa-quote-left"></i>
							Highly recommended! The car was in perfect condition, and the rental process was quick and hassle-free
						<i className="fas fa-quote-right"></i>
						<div className="ratings p-1">
						<i className="fas fa-star"></i>
						<i className="fas fa-star"></i>
						<i className="fas fa-star"></i>
						<i className="fas fa-star"></i>
						<i className="fas fa-star"></i>
						</div>
					</p>
					</div>
					
					<div className="testimonials__picture">
					<img src="images/userIcon.png" alt="client-4 picture" className="rounded-circle img-fluid"/>
					</div>
					
					<div className="testimonials__name">
					<h3>Rajesh</h3>
					<p className="fw-light">Happy Customer</p>
					</div>
				</div>     
				</div>
				<div className="text-center">
				<button className="btn btn-outline-light" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
				<i className="fas fa-arrow-left"></i>
				</button>
				<button className="btn btn-outline-light" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
				<i className="fas fa-arrow-right"></i>
				</button>
				</div>
			</div>
			</div>
		</div>
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fff" fillOpacity="1" d="M0,96L48,128C96,160,192,224,288,213.3C384,203,480,117,576,117.3C672,117,768,203,864,202.7C960,203,1056,117,1152,117.3C1248,117,1344,203,1392,245.3L1440,288L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
		</section>

		<section id="faq" className="faq">
		<div className="container">
			<div className="row text-center">
			<h1 className="display-3 fw-bold text-uppercase">faq</h1>
			<div className="heading-line"></div>
			<p className="lead">frequently asked questions, get knowledge befere hand</p>
			</div>
			
			<div className="row mt-5">
			<div className="col-md-12">
				<div className="accordion" id="accordionExample">
				
				<div className="accordion-item shadow mb-3">
					<h2 className="accordion-header" id="headingOne">
					<button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
						How can I book a taxi from Pune to Mumbai with Vishwa Car Rental?
					</button>
					</h2>
					<div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
					<div className="accordion-body">
						Booking is easy! You can visit our website and use the online booking system or call our customer service hotline to make a reservation.
					</div>
					</div>
				</div>
					
				<div className="accordion-item shadow mb-3">
					<h2 className="accordion-header" id="headingTwo">
					<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
						Are the vehicles air-conditioned?
					</button>
					</h2>
					<div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
					<div className="accordion-body">
						Yes, all our vehicles are equipped with air conditioning for your comfort.
					</div>
					</div>
				</div>
					
				<div className="accordion-item shadow mb-3">
					<h2 className="accordion-header" id="headingThree">
					<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
					Is it possible to make a round trip booking?
					</button>
					</h2>
					<div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
					<div className="accordion-body">
						Absolutely! We offer both one-way and round-trip booking options to cater to your needs.
					</div>
					</div>
				</div>
					
				<div className="accordion-item shadow mb-3">
					<h2 className="accordion-header" id="headingFour">
					<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
						Are the drivers experienced and trained?
					</button>
					</h2>
					<div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
					<div className="accordion-body">
						Yes, all our drivers are experienced professionals who undergo training to ensure a safe and comfortable journey.
					</div>
					</div>
				</div>
				
					
				<div className="accordion-item shadow mb-3">
					<h2 className="accordion-header" id="headingFour">
					<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
						Is the pricing transparent, or are there any hidden charges?
					</button>
					</h2>
					<div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
					<div className="accordion-body">
						Booking is easy! You can visit our website and use the online booking system or call our customer service hotline to make a reservation.
					</div>
					</div>
				</div>
				</div>
			</div>
			</div>
		</div>
		</section>

		<footer className="footer">
		  <div className="container">
			<div className="row">
			  
			  <div className="col-md-4 col-lg-4 contact-box pt-1 d-md-block d-lg-flex d-flex">
				<div className="contact-box__icon">
				  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-phone-call" viewBox="0 0 24 24" strokeWidth="1" fill="none" strokeLinecap="round" strokeLinejoin="round">
					<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
					<path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
					<path d="M15 7a2 2 0 0 1 2 2" />
					<path d="M15 3a6 6 0 0 1 6 6" />
				  </svg>
				</div>
				<div className="contact-box__info">
				  <a href="#" className="contact-box__info--title">+91 7688811106</a>
				  <p className="contact-box__info--subtitle">  24/7 Hours</p>
				</div>
			  </div>  
			  
			  <div className="col-md-4 col-lg-4 contact-box pt-1 d-md-block d-lg-flex d-flex">
				<div className="contact-box__icon">
				  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-mail-opened" viewBox="0 0 24 24" strokeWidth="1" fill="none" strokeLinecap="round" strokeLinejoin="round">
					<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
					<polyline points="3 9 12 15 21 9 12 3 3 9" />
					<path d="M21 9v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10" />
					<line x1="3" y1="19" x2="9" y2="13" />
					<line x1="15" y1="13" x2="21" y2="19" />
				  </svg>
				</div>
				<div className="contact-box__info">
				  <a href="#" className="contact-box__info--title">viswajeetcabservices@gmail.com</a>
				  <p className="contact-box__info--subtitle">Online support</p>
				</div>
			  </div>
			  
			  <div className="col-md-4 col-lg-4 contact-box pt-1 d-md-block d-lg-flex d-flex">
				<div className="contact-box__icon">
				  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-map-2" viewBox="0 0 24 24" strokeWidth="1" fill="none" strokeLinecap="round" strokeLinejoin="round">
					<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
					<line x1="18" y1="6" x2="18" y2="6.01" />
					<path d="M18 13l-3.5 -5a4 4 0 1 1 7 0l-3.5 5" />
					<polyline points="10.5 4.75 9 4 3 7 3 20 9 17 15 20 21 17 21 15" />
					<line x1="9" y1="4" x2="9" y2="17" />
					<line x1="15" y1="15" x2="15" y2="20" />
				  </svg>
				</div>
				<div className="contact-box__info">
				  <a href="#" className="contact-box__info--subtitle">A Wing 404 Gangadhar complex CHS LTD Plot No. 269 Sec. 07<br/> Kamothe Navi Mumbai 410206</a>
				  <p className="contact-box__info--subtitle">Maharashtra</p>
				</div>
			  </div>
			</div>
		  </div>

		  
		  <div className="footer-sm" style={{backgroundColor: "#212121"}}>
			<div className="container">
			  <div className="row py-4 text-center text-white">
				<div className="col-lg-5 col-md-6 mb-4 mb-md-0">
				  connect with us on social media
				</div>
				<div className="col-lg-7 col-md-6">
				  <a href="#"><i className="fab fa-facebook"></i></a>
				  <a href="#"><i className="fab fa-twitter"></i></a>
				  <a href="#"><i className="fab fa-github"></i></a>
				  <a href="#"><i className="fab fa-linkedin"></i></a>
				  <a href="#"><i className="fab fa-instagram"></i></a>
				</div>
			  </div>
			</div>
		  </div>

		  
		  <div className="container mt-5">
			<div className="row text-white justify-content-center mt-3 pb-3">
			  <div className="col-12 col-sm-6 col-lg-6 mx-auto">
				<h5 className="text-capitalize fw-bold">Vishwajeet Cab Services</h5>
				<hr className="bg-white d-inline-block mb-4" style={{width: "60", height: "2"}}/>
				<p className="lh-lg">
				  We are Vishwajeet Cab Service are registerd compnay having experience of 10+ years. and served more then 1000+ happy Customers.
				</p>
			  </div>
			  <div className="col-12 col-sm-6 col-lg-2 mb-4 mx-auto">
				<h5 className="text-capitalize fw-bold">Regular Trip</h5>
				<hr className="bg-white d-inline-block mb-4" style={{width: "60", height: "2"}}/>
				<ul className="list-inline campany-list">
				  <li><a href="#">Pune To Mumbai</a></li>
				  <li><a href="#">Pune To Nashik</a></li>
				  <li><a href="#">Pune To Aurangabad</a></li>
				  <li><a href="#">Pune To Satara</a></li>
				  <li><a href="#">Pune To Solapur</a></li>
				  <li><a href="#">Pune To Kolhapur</a></li>
				  <li><a href="#">Pune To Mahableswar</a></li>
				  <li><a href="#">Pune To Alibug</a></li>
				</ul>
			  </div>
			  <div className="col-12 col-sm-6 col-lg-2 mb-4 mx-auto">
				<h5 className="text-capitalize fw-bold">Regular Trips</h5>
				<hr className="bg-white d-inline-block mb-4" style={{width: "60",height: "2"}}/>
				<ul className="list-inline campany-list">
				  <li><a href="#">Mumbai To Pune</a></li>
				  <li><a href="#">Mumbai To Nashik</a></li>
				  <li><a href="#">Mumbai To Aurangabad</a></li>
				  <li><a href="#">Mumbai To Satara</a></li>
				  <li><a href="#">Mumbai To Solapur</a></li>
				  <li><a href="#">Mumbai To Kolhapur</a></li>
				  <li><a href="#">Mumbai To Mahableswar</a></li>
				  <li><a href="#">Mumbai To Alibug</a></li>
				</ul>
			  </div>
			  <div className="col-12 col-sm-6 col-lg-2 mb-4 mx-auto">
				<h5 className="text-capitalize fw-bold">contact</h5>
				<hr className="bg-white d-inline-block mb-4" style={{width: "60", height: "2"}}/>
				<ul className="list-inline campany-list">
				  <li><a href="#">Mumbai To Goa</a></li>
				  <li><a href="#">Mumbai To Bhandardara</a></li>
				  <li><a href="#">Pune To Goa</a></li>
				  <li><a href="#">Mumbai To Malvan</a></li>
				  <li><a href="#">Pune To Malvan</a></li>
				</ul>
			  </div>
			</div>
		  </div>

		  
		  <div className="footer-bottom pt-5 pb-5">
			<div className="container">
			  <div className="row text-center text-white">
				<div className="col-12">
				  <div className="footer-bottom__copyright">
					&COPY; Copyright 2023 <a href="#">Vishwajeet Cab Services</a> | Created by <a href="http://www.ellipsesofttech.com" target="_blank">Ellipse Software Technology</a><br/><br/>            
				  </div>
				</div>
			  </div>
			</div>
		  </div>
		</footer>

      </header>
    </div>
  );
}

export default App;
